.bg_ {
  height: 220px;
  position: relative;
  background: linear-gradient(180deg, #2a7eb4 0%, #000000 200.78%);
  z-index: -1;
  overflow: hidden;
  width: 100%;
}
.bg_ img {
  position: absolute;
  bottom: 0;
  z-index: -1;
  /* display: none; */
}
.rru {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70%;
  color: white;
}
.rru h1 strong {
  font-weight: 700;
}
.content {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  width: 90%;
  margin-left: 5%;
  margin-bottom: 50px;
  margin-top: -80px;
  z-index: 1;
  font-weight: 400;
}
.content h2 {
  font-weight: 700;
  margin-bottom: 10px;
}
.content ul {
  list-style: none;
}
.content h2 {
  margin-top: 10px;
}
.content p,
.content li {
  font-weight: 200;
}
.content strong {
  font-weight: 700;
}
.content table {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content td {
  padding: 10px;
}

@media (max-width: 780px) {
  .content table tr {
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 7% - 60px);
  }
  .content table a {
    word-wrap: break-word;
  }
}
