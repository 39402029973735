@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap");

.poppins {
  font-family: "Poppins", sans-serif;
}
.sans {
  font-family: "Josefin Sans", sans-serif;
}
.lato {
  font-family: "Lato", serif;
}
.lora {
  font-family: "Lora", serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 200;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  display: none;
}
.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.routes {
  height: calc(100vh - 70px);
  overflow-y: auto;
}
:root {
  --orange: #ff6a02;
  --primary: #10375c;
  --blue01: #42a0d6;
  --gra01: #eff0f2;
  --text: #333333;
  --blue02: #2a7eb4;
  --blue03: #2672a3;
}
.shape0 {
  width: 200px;
  object-fit: contain;
  position: absolute;
}
.flex {
  display: flex;
}
.container {
  padding: 7%;
}
.title {
  font-family: "Josefin Sans", sans-serif;
  /* color: var(--text); */
  text-align: center;
  font-weight: 900;
  font-size: 2em;
}
.outline {
  margin-left: 10px;
  border: 2px solid white;
  background-color: transparent !important;
}
.bvb {
  background-color: #0d2c4a;
  color: white;
  position: relative;
  /* padding-top: 9%; */
}
.bvb img {
  width: 120px;
  object-fit: contain;
  position: absolute;
  left: calc(50% - 60px);
  top: -60px;
}
.bvb p {
  font-size: 0.9em;
  font-weight: 400;
}
.bvb span,
.pnv {
  opacity: 0.5;
  font-weight: 400;
  position: relative;
}
.bvb span::after,
.pnv::after {
  content: "";
  width: 40%;
  height: 1px;
  background-color: white;
  position: absolute;
  top: 50%;
  right: -50%;
}
.bvb h2 {
  font-weight: 700;
  margin-top: 10px;
}
@media (max-width: 760px) {
  .desktop {
    display: none;
  }
  .socials {
    margin-bottom: 20px;
  }
  .nnn {
    margin-top: 20px;
  }
}

@media (min-width: 760px) {
  .bvb img {
    width: 250px;
    left: calc(50% - 125px);
  }
  .mobile {
    display: none;
  }
}
@media (min-width: 1000px) {
  .bvb {
    margin-top: 60px;
  }
}
.c_ms {
  font-weight: 700;
  font-size: 0.7em;
  margin-bottom: 10px;
}
.error {
  color: #ff6a02;
}
.success {
  color: green;
}
.sss .bbb {
  background-color: none;
  background: none;
  border: none;
  outline: none;
  font-weight: 600;
  /* background-color: green; */
  width: fit-content;
  padding: 10px;
  padding-left: 0;
}
.agreement {
  width: 100vw;
  height: 100vh;
}
label {
  font-weight: 900;
  font-size: 12px;
}
.faq_ {
  background-color: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 10px;
  transform: 0.3s all ease-in-out;
}
.faq_ h2 {
  text-align: center;
  font-size: 1em;
  font-weight: 700;
}
.faq_ p {
  padding: 10px;
  font-size: 0.9em;
  font-weight: 400;
}
.faq_ .ico {
  background-color: var(--primary);
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s all ease-in-out;
  margin-top: 10px;
  cursor: pointer;
}
.faq_ .ico.ooo {
  transform: rotate(180deg);
}
.arrow_up {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  background-color: var(--orange);
  cursor: pointer;
}
@media (min-width: 700px) {
  .fqs_ {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    margin-top: 30px;
  }
  .faq_ {
    max-width: 300px;
    margin-right: 10px;
  }
}
.share span {
  display: block;
}
.share span,
.share span strong {
  font-weight: 400;
  font-size: 1.3em;
}
.share span strong {
  font-weight: 700;
}
.share textarea {
  width: 100%;
  resize: vertical;
  height: 80px;
  margin-top: 10px;
  padding: 10px;
  border: 2px solid var(--gra01);
}
.sss {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sss a {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gra01);
  border-radius: 50%;
  text-decoration: none;
  color: var(--primary);
}
.sss a:hover {
  color: var(--orange);
}
.share {
  max-width: 700px;
}
.profile img {
  width: 120px !important;
  height: 120px !important;
  object-fit: contain !important;
  display: block !important;
  position: relative !important;
  top: 15px !important;
  margin-bottom: 30px;
}
.profile p {
  text-align: center;
  margin-top: 10px;
}
.content p a {
  word-wrap: break-word !important;
}
.rfp ul {
  list-style-type: circle !important;
}
