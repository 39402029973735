.product {
  box-shadow: 0px 24px 60px rgba(51, 51, 51, 0.16);
  width: 370px;
  height: 440px;
  margin-bottom: 100px;
  background-color: white;
}
.lists {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sub {
  text-align: center;
  color: #999999;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.img {
  width: 100%;
  height: 50%;
  position: relative;
  background-color: var(--blue01);
  position: relative;
}
.img img {
  position: absolute;
  bottom: -10px;
  right: 0;
}
.product:nth-child(even) .img,
.org {
  background: linear-gradient(180deg, #ff6a02 0%, #fbac0e 100%), #c4c4c4;
}
.abt {
  padding: 30px;
}
.abt h2 {
  font-weight: 700;
  margin-bottom: 10px;
  color: #333333;
  text-transform: capitalize;
}
.abt p {
  font-weight: 400;
  font-size: 0.9em;
}
@media (max-width: 1000px) {
  /* .lists {
    display: flex;
    flex-wrap: nowrap;
    white-space: no;
    justify-content: center;
  }
   */
  .lists {
    position: relative;
  }
  .product {
    margin-bottom: 0px;
    transform: scale(0.8);
  }
  #products {
    position: relative;
  }
  .navs {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--orange);
    position: absolute;
    z-index: 2;
    top: calc(50% + 40px);
  }
  .navs.left {
    left: 4%;
    transform: rotate(180deg);
  }
  .navs.right {
    right: 4%;
  }
}

@media (min-width: 760px) {
  .navs {
    display: none;
  }
}
