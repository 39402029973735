.benefits .title {
  font-size: 1em;
}
.bg_cnt {
  background-color: #0d2c4a;
  margin-bottom: 60px;
  position: relative;
  margin-top: 40px;
  z-index: 10;
}

.bg_cnt img {
  width: 215px;
  object-fit: contain;
  margin-left: calc(100vw / 4 - 15%);
  /* margin-right: auto; */
}
.bg_cnt img:first-child {
  margin-top: -40px;
  z-index: 10;
}
.bg_cnt img:last-child {
  margin-bottom: -40px;
}
.bg_cnt::before,
.bg_cnt::after {
  content: "";
  width: 100%;
  height: 20px;
  background-color: white;
  position: absolute;
  z-index: -1;
  left: 0;
  border-radius: 0 0 80% 80%;
}
.bg_cnt::before {
  top: -2px;
}

.bg_cnt::after {
  bottom: -2px;
  border-radius: 80% 80% 0 0;
}
.blue__ {
  position: absolute;
  width: 100px !important;
  object-fit: contain;
  left: 0;
  top: 30%;
  z-index: -1;
}
.balls {
  border: 5px solid #ffba49;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: -1;
}
.balls:nth-child(1) {
  width: 32px;
  height: 32px;
  top: 40px;
}
.balls:nth-child(2) {
  width: 200px;
  height: 200px;
  top: 50px;
  left: 20%;
}
.balls:nth-child(3) {
  width: 131px;
  height: 131px;
  right: 0;
  top: 50%;
}
.balls:nth-child(4) {
  width: 32px;
  height: 32px;
  top: 50%;
  left: 40%;
  z-index: 11;
}
.bnf {
  margin: 30px 0;
}
.bnf img {
  width: 40px;
  object-fit: contain;
}
.bnf h2 {
  font-weight: 700;
  font-size: 2em;
  margin-right: 10px;
}

.bnf p {
  color: #37474f;
  font-size: 0.9em;
  font-weight: 400;
}
.lf {
  margin-right: 15px;
}
.rr {
  margin-left: 15px;
}
.bg_cnt {
  display: flex;
}
.cn {
}

@media (min-width: 760px) {
  .bg_cnt .bnf > * {
    color: white;
  }
  .inn {
    width: 700px;
    margin-left: auto;
  }
  .inn img {
    display: none;
  }
  .bg_cnt .bnf {
    width: 300px;
  }
  .bg_cnt .bnf p {
    opacity: 0.8;
  }
  .cn {
    position: relative;
  }
  .bg_cnt .bnf:nth-child(even) {
    margin-left: auto !important;
  }
  .bg_cnt img.jjj {
    margin: unset;
    width: 350px;
    display: block;
  }
  .bg_cnt {
    max-height: 800px;
  }
  .bg_cnt img.jjj:first-child {
    margin-top: -50%;
  }
  .bg_cnt img.jjj:last-child {
    margin-bottom: -50%;
  }
  .bg_cnt {
    margin: 150px 0;
  }

  .balls:nth-child(2) {
    width: 200px;
    height: 200px;
    top: 150px;
    left: 5%;
  }
  .balls:nth-child(3) {
    width: 131px;
    height: 131px;
    left: 30%;
    top: 50%;
  }
  .balls:nth-child(4) {
    top: 53%;
    left: 20%;
  }
  .ppb {
    position: absolute;
    top: 40%;
    right: 0%;
  }
  .benefits .title {
    font-size: 1.5em;
  }
  .benefits {
    /* background-color: green; */
    margin-top: 30px;
  }
}

@media (min-width: 1100px) {
  .bg_cnt {
    margin-bottom: 0px;
  }
  .bg_cnt {
    margin-top: 100px;
  }
}

.card {
  background-color: #10375c;
  padding: 20px;
  border-radius: 0 20px 0 20px;
  color: white;
  width: fit-content;
  position: relative;
  margin: 30px 0;
}
.card h2 {
  font-weight: 700;
  margin-bottom: 10px;
}
.card p {
  font-size: 0.9em;
  font-weight: 400;
}
.about {
  margin-bottom: 60px;
}
.card::before {
  content: "";
  width: 100%;
  height: 110%;
  border-radius: 20px;
  background-color: white;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
}
/* .card::after {
  content: "";
  width: 90%;
  height: 120%;
  border-radius: 20px;
  background-color: white;
  position: absolute;
  right: 10%;
  top: 0;
  z-index: -1;
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
} */
.pics {
  position: relative;
  margin-top: 40px;
}
.about1 {
  width: 100%;
  margin-top: 20px;
}
.about2 {
  position: absolute;
  top: -20px;
  left: 0;
  /* background-color: green; */
}
.about3 {
  position: absolute;
  right: -40px;
  top: 15%;
}
.about4 {
  position: absolute;
  right: -40px;
  top: -40px;
}
@media (min-width: 760px) {
  .pics {
    max-width: 500px;
  }
  .abt_ {
    display: flex;
    justify-content: space-between;
  }
  .txtxs {
    max-width: 300px;
  }
}

@media (min-width: 1100px) {
  .about {
    margin-top: 100px;
  }
  .pics {
    max-width: 700px;
  }
}

@media (max-width: 700px) {
  .pics {
    width: 100%;
    overflow: hidden;
  }
}
