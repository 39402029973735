.get_started .form {
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
  padding: 20px;
}
.get_started .form input[type="submit"] {
  margin: unset;
  border-radius: 30px;
  margin-top: 20px;
  font-weight: 600;
}
.acc {
  align-items: center;
}
.acc .rad {
  margin-left: auto;
  /* align-items: center; */
}
.acc .rad input {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.acc .rad span {
  margin-left: 10px;
}
@media (min-width: 760px) {
  .get_started {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .get_started .form {
    max-width: 700px;
  }
}

@media (max-width: 760px) {
  .acc {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .acc .rad {
    margin-left: 0;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
