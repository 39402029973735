.testimonials {
  position: relative;
}
.box {
  background-color: white;
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
  border-radius: 20px;
  font-size: 0.9em;
  position: relative;
  padding: 20px;
}

.box::after {
  content: "";
  width: 80%;
  height: 30px;
  background-color: red;
  position: absolute;
  z-index: -1;
  right: 10px;
  box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
}
/* .box::before {
  background-color: red;
  height: 60px;
} */
.box::after {
  background-color: white;
  border-radius: 20px;
}
.box p {
  color: #94a2b3;
  font-weight: 400;
}
.box span {
  font-weight: 700;
  font-size: 1em;
  display: block;
}
.box a {
  color: var(--primary);
  font-weight: 400;
  text-decoration: none;
  text-align: right;
}
.box a:hover {
  text-decoration: underline;
}
.box div {
  margin-top: 10px;
  text-align: right;
}
.prd {
  width: 180px;
  object-fit: contain;
  position: absolute;
  right: 0%;
  bottom: 20%;
  z-index: -1;
  /* right: -20%;
  bottom: 20px; */
}
.pb {
  position: absolute;
  bottom: 10%;
  z-index: -1;
  transform: rotate(90deg);
}
@media (max-width: 760px) {
  .box img {
    border-radius: 50%;
    margin-bottom: 6px;
  }
  .box p {
    font-size: 0.7em;
  }
}
.arrows {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
}
.arrows div {
  padding: 10px;
  cursor: pointer;
}
.arrows img {
  width: 40px;
  object-fit: contain;
}
.arrows div:first-child img {
  transform: rotate(-180deg);
  width: 30px;
}

@media (min-width: 700px) {
  .box {
    padding-left: 160px;
    margin-top: 80px;
    position: relative;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .img_i {
    width: 150px;
    position: absolute;
    left: 0;
    top: -50%;
  }
  .arrows {
    justify-content: center;
    align-items: center;
  }
}
