.enquire {
  position: relative;
}
.form input,
.form textarea,
.select .val,
.imput_type {
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  color: #838383;
  font-weight: 400;
  background-color: #eff0f2;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}
.form {
  width: 100%;
  margin-top: 20px;
  background-color: white;
  z-index: 10;
}
.inputs {
  display: flex;
  justify-content: space-between;
}
.inputs input,
.inputs .select {
  width: 49%;
}
.form span {
  font-size: 0.7em;
  /* display: none; */
  font-weight: 900;
  display: block;
}
.qqq {
  align-items: center;
  display: flex;
}
.form input[type="checkbox"] {
  width: 20px;
  height: 20px;
  display: block;
  margin-left: 10px;
  cursor: pointer;
}
.form textarea {
  height: 90px;
  resize: vertical;
  width: 100%;
}
.enquire::before {
  content: "";
  width: 100%;
  height: 300px;
  background-color: #0d2c4a;
  position: absolute;
  left: 0;
  top: 40%;
  z-index: -1;
}
.qqq span {
  display: block !important;
  width: 90%;
}
.form input[type="submit"] {
  color: white;
  background-color: #0d2c4a;
  margin-top: 10px;
  width: fit-content;
  width: 150px;
  /* padding: 10px 50px; */
  margin-left: calc(50% - 70px);
  cursor: pointer;
}
@media (min-width: 760px) {
  .form input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
  }
  .form {
    max-width: 70%;
    box-shadow: 0px 18px 52.85px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  .form span {
    font-size: 0.8em;
  }
  .enquire .title {
    margin-bottom: 60px;
  }
}

@media (max-width: 760px) {
  .enquire::before {
    display: none;
  }
  .qqq {
    margin-bottom: 10px;
  }
}
.select {
  position: relative;
}
.select .val {
  font-weight: 900;
  font-size: 0.8em;
  text-transform: capitalize;
  cursor: pointer;
}
.select .vals {
  width: 100%;
  max-height: 200px;
  background-color: white;
  position: absolute;
  top: 40px;
  z-index: 100;
  overflow-y: auto;
  display: none;
}
.select.opened .vals {
  display: block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.vals div {
  width: 100%;
  min-height: 40px;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  font-weight: 900;
  cursor: pointer;
  font-size: 0.9em;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.sss h2 {
  text-align: center;
  font-weight: 900;
  font-size: 1em;
  margin-bottom: 10px;
}
.add_doc {
  width: 100%;
}
.add_doc div {
  width: calc(100% - 100px);
}
.add_doc button {
  width: 90px;
  height: 40px;
  margin-left: 10px;
  background-color: var(--orange);
  border: none;
  cursor: pointer;
  color: white;
  outline: none;
}
@media (max-width: 600px) {
  .add_doc {
    flex-direction: column;
  }
  .add_doc div {
    width: 100%;
  }
  .add_doc button {
    margin-bottom: 10px;
  }
}
.add_btn {
  padding: 10px;
  border: none;
  outline: none;
  margin-left: calc(100% - 110px);
  background-color: var(--blue03);
  color: white;
  border-radius: 8px;
}
.form h3 {
  font-weight: 700;
  margin-bottom: 10px;
}
.bb_ss {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-weight: 900;
}
.cookies_message {
  padding: 10px;
  background-color: var(--orange);
  position: fixed;
  bottom: 0;
  z-index: 200;
  color: white;
}
.cookies_message .btns {
  display: flex;
  margin-top: 10px;
}
.cookies_message .btns div {
  padding: 10px 15px;
  background-color: var(--blue02);
  cursor: pointer;
  margin-right: 10px;
  font-weight: 900;
  border-radius: 5px;
}
.cookies_message .btns div:last-child {
  background-color: transparent;
}
.njs {
  margin-top: 15px;
  margin-left: 10px;
}

@media (min-width: 760px) {
  .cookies_message {
    width: fit-content;
    max-width: 400px;
    left: calc(50% - 200px);
    bottom: 30px;
  }
}
