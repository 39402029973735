main {
  width: 100vw;
  background: linear-gradient(180deg, #2a7eb4 0%, #000000 100%);
  padding: 7%;
  padding-right: 5%;
  padding-bottom: 10%;
  position: relative;
}
.inf {
  color: white;
}
.inf h2 {
  font-weight: 700;
  font-size: 2.6em;
}
.inf p {
  font-weight: 200;
  font-size: 0.8em;
  max-width: 400px;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 21px;
}
.btn-primary {
  padding: 15px 25px;
  background-color: var(--orange);
  width: fit-content;
  text-transform: uppercase;
  color: white;
  font-size: 0.7em;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}
.catt {
  max-width: 550px;
  object-fit: contain;
  position: absolute;
  right: 0%;
  bottom: -35%;
  z-index: 2;
}
.watar-mark {
  position: absolute;
  top: 0;
  opacity: 0.1;
  width: 60%;
  right: 3%;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}
.txt_ {
  position: absolute;
  top: 10%;
  max-width: 400px;
  color: white;
  right: 7%;
  z-index: 2;
}
.txt_ span {
  font-weight: 400;
}
.shape {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 400px;
  object-fit: contain;
  z-index: 1;
}
.infs__ {
  display: flex;
  padding-top: 10%;
  padding-bottom: 0% !important;
  position: relative;
}
.infs__ div {
  width: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infs__ div:first-child {
  justify-content: flex-start;
}
.infs__ div:last-child {
  justify-content: flex-end;
}
.infs__ div h2 {
  color: var(--blue02);
  font-weight: 700;
  margin-right: 10px;
  font-size: 2em;
}
.infs__ div span {
  font-weight: 400;
  color: var(--text);
  font-size: 1.1em;
}
.infs__ .shape0 {
  right: 7%;
  top: 60%;
}
@media (min-width: 1300px) {
  .catt {
    max-width: 900px;
    right: 7%;
    bottom: -37%;
  }
}

@media (max-width: 760px) {
  .txt_ {
    position: relative;
    padding: 7%;
  }
  header {
    /* display: none; */
  }
  .catt {
    position: relative;
    width: calc(100vw - 40px);
    right: 0;
    bottom: 0;
  }
  .shape {
    display: none;
  }

  .inf h2 {
    font-size: 2.4em;
  }
  .infs__,
  .infs__ div {
    flex-direction: column;
  }
  .infs__ div {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }
  .infs__ div h2 {
    font-size: 3em;
  }

  .points_m {
    width: 50px;
    object-fit: contain;
    position: absolute;
    right: 20px;
  }
  .top {
    left: 20px;
    bottom: -40px;
  }
  .topr {
    left: -70px;
    top: -30% !important;
    z-index: -1;
  }
}
