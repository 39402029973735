footer {
  background-color: #0d2c4a;
  color: white;
  padding-top: 5% !important;
  padding-bottom: 0% !important;
}
.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer .dist h2 {
  font-weight: 700;
}
.footer .dist p {
  max-width: 300px;
  color: #f8f8f8;
  margin-top: 20px;
}
.dist h3 {
  color: white;
  opacity: 0.3;
  font-weight: 400;
}

.dist ul {
  list-style: none;
}
.dist ul li,
.dist ul li a {
  padding: 3px 0px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-weight: 700;
}
.dist ul li img {
  margin-right: 10px;
  /* display: flex;
  align-items: center; */
}

.dist ul li a:hover {
  text-decoration: underline;
}
.socials {
  margin-top: 15px;
}
.socials a {
  color: white;
  padding-right: 20px;
  cursor: pointer;
}
.dist ul {
  margin-top: 20px;
}
.f_note {
  text-align: center;
  font-size: 0.9em;
  margin-top: 30px;
  opacity: 0.6;
}
.copy {
  padding: 20px;
  text-align: center;
  font-weight: 700;
  font-size: 0.9em;
}

.input {
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 30px;
  height: 50px;
  display: flex;
  margin-top: 20px;
  max-width: 375px;
}
.input input {
  height: 100%;
  outline: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding-left: 10px;
  font-weight: 500;
  width: 60%;
}
.input div {
  width: 40%;
  height: 100%;
  border: none;
  border-radius: 30px;
  outline: none;
  background-color: white;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
}
@media (max-width: 760px) {
  .input {
    width: calc(100vw - 22%);
  }
  .dist ul li img {
    display: none;
  }
}

@media (min-width: 1100px) {
  .input {
    width: 380px !important;
  }
  .input input {
    padding-left: 40px;
  }
}

.socials a {
  text-decoration: none;
}
