.sponsor_container .hero {
  background: linear-gradient(180deg, #2a7eb4 0%, #000000 100%);
  width: 100vw;
  height: 50vh;
  position: relative;
}
.sponsor_container .hero img {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
  opacity: 0.5;
}
.sponsor_container .hero img.shape {
  width: 30%;
  /* background-color: green; */
  /* object-fit: contain; */
  height: 300px;
}

.sponsor_container .container.jjj {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  color: white;
}
.sponsor_container .container.jjj h2 {
  font-weight: 700;
  font-size: 3em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.sponsor_container .container.jjj p {
  margin-top: 3%;
  max-width: 600px;
}
.btn_btn_primary {
  background-color: var(--orange);
  padding: 15px 20px;
  left: calc(50% - 30px);
  position: absolute;
  bottom: 10%;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  font-weight: 400;
}
.amt li {
  align-items: center;
  margin-bottom: 10px;
}
.amt li span {
  font-weight: 400;
}
.amt li span:first-child {
  margin-left: 10px;
}
.amt li input {
  max-width: 400px;
}
.amt li span.pn {
  margin-left: 0px;
  margin-right: 10px;
}
.sp_fm input {
  position: relative;
}
.sp_fm h3 {
  text-align: center;
  margin-bottom: 20px;
}
.amt li span span {
  color: var(--orange);
  font-weight: 400;
}
.inp_ {
  align-items: center;
}
.inp_ span {
  margin-left: 10px;
}
.sp_fm span,
.sp_fm label {
  font-weight: 400 !important;
}

@media (max-width: 760px) {
  .sponsor_container .hero,
  .sponsor_container .hero img {
    height: 80vh;
  }
  .btn_btn_primary {
    left: 27%;
  }
  .sponsor_container .container.jjj {
    padding-top: 25%;
  }
  .sponsor_container .container.jjj h2 {
    font-size: 2.25em;
    letter-spacing: 0px;
  }
}
input[type="checkbox"] {
  cursor: pointer;
}
button {
  cursor: pointer;
}
.btn_pp {
  background-color: var(--primary);
  color: white;
  padding: 15px;
  border: none;
  outline: none;
  border-radius: 4px;
}
@media (min-width: 760px) {
  .amt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  .sp_fm {
    margin-left: auto;
    margin-right: auto;
  }
  .content_ {
    padding-top: 2%;
  }
}
