.bg_s {
  background-color: #10375c;
  color: white;
}
.services {
  /* margin-bottom: 30px; */
}
.bg_s img {
  width: 120px;
  margin-left: auto;
  object-fit: contain;
}
.bg_s h2 {
  font-weight: 700;
  font-size: 1.2em;
}
.bg_s p {
  font-weight: 400;
  max-width: 300px;
}
.nfv {
  margin-top: 30px;
}
.nfv div {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.nfv .left {
  transform: rotate(180deg);
  margin-right: 60px;
}
.s_inf {
  width: 100%;
  background-color: #dfdfdfb2;
}
.qs_ {
  width: 50%;
  padding-right: 20px;
}
.qs_ div {
  width: 100%;
  min-height: 50px;
}
.qs_ div:nth-child(3) {
  min-height: 90px;
}
.qs_ div span {
  font-weight: 400;
  font-size: 0.9em;
  color: #37474f;
  text-align: right;
  display: block;
}
.ans {
  padding-right: 0;
  padding-left: 20px;
}
.ans div span {
  text-align: left;
  color: #66b7df;
}

@media (min-width: 760px) {
  .s_inf {
    background-color: transparent;
    max-width: 900px;
    margin-top: -5%;
    margin-left: auto;
    margin-right: auto;
  }
  .ans {
    background-color: white;
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
    border-radius: 10px;
    /* margin-top: -50px; */
    position: relative;
  }
  .ans::after {
    content: "";
    width: 100%;
    height: 105%;
    border-radius: 10px;
    background-color: white;
    position: absolute;
    top: 0;
    box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);
    z-index: -1;
  }
  .qs_ {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .qs_ div span {
    font-size: 1.2em;
  }
  .bg_s {
    position: relative;
  }
  .igo {
    background-color: var(--orange);
    position: absolute;
    top: calc(50% - 40px);
  }
  .left {
    left: 13%;
  }
  .right {
    right: 13%;
  }
  .bg_s .container {
    padding-left: 20% !important;
  }
  .bg_s img {
    width: 200px;
    position: absolute;
    right: 20%;
    bottom: 0;
  }
  .s_inf {
    position: relative;
  }
  .impp {
    width: 100px;
    object-fit: contain;
    position: absolute;
    z-index: -1;
  }
  .impp.ft {
    left: 15%;
    top: 30%;
    /* display: none; */
  }
  .impp.sd {
    /* display: none; */
    right: 10%;
    bottom: 0;
  }
}

@media (min-width: 1100px) {
  .bg_s .container {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
  }
  .services {
    margin-top: 80px;
  }
  .bg_s {
    margin-top: 40px;
  }
  .impp.ft {
    left: 10%;
    top: 30%;
    /* display: none; */
  }
  .impp.sd {
    /* display: none; */
    right: 5%;
    bottom: 0;
  }
}
