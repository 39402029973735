header {
  width: 100vw;
  height: 70px;
  background-color: var(--blue02);
  padding-left: 5.5%;
  padding-right: 5.5%;
  box-shadow: 0px 4px 40px rgba(51, 51, 51, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.lll {
  display: flex;
  align-items: center;
}
.shadow {
  box-shadow: 0px 4px 40px rgba(51, 51, 51, 0.08);
}
.lll h2 {
  font-weight: 700;
  margin-left: 40px;
  display: none;
}
.btn-accent {
  border-radius: 4px;
  padding: 12px !important;
}
header ul {
  display: flex;
  list-style: none;
}
header ul li {
  padding: 10px;
  cursor: pointer;
  color: white;
  font-weight: 400;
}
header ul a {
  text-decoration: none;
}
header ul li.active {
  font-weight: 900;
}

header.scrolled {
  background-color: white;
}

header.scrolled ul li {
  color: var(--text);
}
header.scrolled .btn-accent {
  background-color: var(--blue03);
}
header .menu {
  display: none;
}

header,
header > * {
  transition: 0.3s all ease-in-out;
}
.btn {
  display: none;
}
@media (max-width: 760px) {
  header .logo {
    display: block;
  }
  header .menu {
    display: block;
  }
  .btn {
    display: block;
  }
  header .menu div {
    width: 35px;
    height: 4px;
    background-color: white;
    margin-bottom: 4px;
  }
  header.scrolled .menu div {
    background-color: var(--blue02);
  }
  header .main-btn {
    display: none;
  }
  header ul {
    display: none;
  }
  header ul {
    position: absolute;
    width: 100%;
    min-height: 200px;
    background-color: white;
    top: 0px;
    left: 0;
    z-index: 10;
    flex-direction: column;
  }
  header ul.opened {
    display: flex;
    z-index: 100;
  }
  header ul li {
    padding: 20px;
    color: var(--blue02) !important;
  }
}
.logo_link img {
  width: 200px;
  object-fit: contain;
  height: auto;
}
