.app_d {
  background-color: var(--expo-color-base-light-gray-100);
}
.app_d input[type="file"] {
  display: none;
}
.app_d .container {
  padding: 3%;
}
.routes_d {
  display: flex;
}
.inp {
  width: 100%;
  margin-top: 10px;
}
.inp label {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.6;
  letter-spacing: -0.009rem;
  cursor: pointer;
}
.inp input {
  margin-top: 6px;
}
.inps {
  display: flex;
  justify-content: space-between;
}
.inps .inp {
  width: 45%;
}
nav {
  width: 200px;
  height: 100%;
  background-color: var(--expo-theme-background-default);
  border-right: 1px solid var(--expo-theme-border-default);
}
nav ul li {
  width: 100%;
  padding: 15px;
  cursor: pointer;
  font-weight: 700;
}
nav ul {
  height: 100%;
  display: flex;
  flex-direction: column;
}
nav a {
  text-decoration: none;
  color: var(--primary);
}
nav ul .lll {
  margin-top: auto;
}
nav ul li:hover,
nav ul li.active {
  background-color: var(--expo-color-base-light-gray-200);
}
.d_header {
  box-shadow: unset;
  position: relative;
  background-color: var(--expo-theme-background-default);
  border: 1px solid var(--expo-theme-border-default);
  padding: 0 3%;
}
.prof {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.prof label {
  line-height: 1;
  border-radius: 6px;
  border: 0px;
  outline: none;
  padding: 0px 16px;
  text-decoration: none;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  background-color: var(--expo-theme-button-secondary-background);
  color: var(--expo-theme-button-secondary-foreground);
  cursor: pointer;
  transition: all 150ms ease 0s;
  transform: translate3d(0px, 0px, 0px);
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 20px;
}
.prof .icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--expo-color-base-light-gray-200);
  cursor: pointer;
}
.dcard {
  background-color: var(--expo-theme-background-default);
  border: 1px solid var(--expo-theme-border-default);
  padding: 20px;
  border-radius: 10px;
}
.c_h {
  /* align-items: center; */
  padding-bottom: 2%;
}
.c_h h3 {
  font-weight: 600;
  margin-left: 10px;
  color: var(--primary);
}
.user_inf {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.user_inf .bell,
.user_inf .icon_pic {
  width: 35px;
  height: 35px;
  background-color: var(--expo-color-base-light-gray-100);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.user_inf .icon_pic {
  width: 50px;
  height: 50px;
  position: relative;
}
.user_inf .bell svg {
  transform: scale(0.9);
}
.user_inf h2 {
  margin: 0 10px;
  font-weight: 700;
  font-size: 1em;
  color: var(--primary);
}
.more_acct_op,
.notifications {
  width: 200px;
  min-height: 100px;
  background-color: white;
  position: absolute;
  top: 100%;
  right: -50%;
  padding: 0px;
  display: block;
  z-index: 100;
}
.notifications {
  width: 400px;
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
}
.notifications ul li {
  color: var(--primary);
  font-size: 0.9em;
  color: var(--text);
}
.notifications ul li span {
  display: block;
  margin-left: auto;
  font-weight: 900;
  font-size: 0.8em;
  margin-left: 76%;
}
.more_acct_op li {
  color: var(--primary);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  font-weight: 700;
  font-size: 0.9em;
}
.more_acct_op li span {
  font-weight: 700;
}
.more_acct_op li:last-child {
  border-top: 1px solid var(--gra01);
  margin-top: auto;
}
.more_acct_op li:first-child {
  border-bottom: 1px solid var(--gra01);
}
.rrr {
  flex: 1;
  overflow-y: auto;
}
.input_type {
  line-height: 1.625;
  letter-spacing: -0.011rem;
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  box-shadow: var(--expo-theme-shadows-input);
  background: var(--expo-theme-background-default);
  border: 1px solid var(--expo-theme-border-default);
  outline: none;
}
@media (max-width: 760px) {
  .more_acct_op {
    left: -150px;
    right: 0;
  }

  .notifications {
    width: 90vw;
    left: -35vw;
    z-index: 10;
    top: 50px;
    max-height: calc(70vh - 80px);
    height: calc(70vh - 80px) !important;
    z-index: 11;
  }
  .notifications ul li span {
    margin-left: 60%;
  }
}
.app_d {
  width: 100vw;
  height: 100vh;
}
.routes_d {
  height: calc(100vh - 70px);
  overflow-y: auto;
}

@media (min-width: 1000px) {
  .profile {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
}

@media (max-width: 760px) {
  nav {
    position: absolute;
    z-index: 20;
    /* opacity: 0; */
    left: -100vw;
    height: calc(100vh - 70px);
    transition: 0.3s all ease-in;
  }
  nav.opened {
    left: 0;
  }
  .menu {
    width: 40px;
    height: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: red; */
  }
  .menu div {
    transition: 0.3s all ease-in-out;
    width: 30px !important;
    border-radius: 5px;
    background-color: var(--expo-color-base-light-gray-900) !important;
  }
  .menu.opened div:nth-child(2) {
    transform: translateX(20px);
  }
  .bell {
    margin-right: 20px;
  }
  .user_inf h2 {
    display: none;
  }
  .inps .inp {
    width: 49%;
  }
}
